import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// UI
import {
    Alert,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Snackbar,
    SpeedDial,
    SpeedDialAction,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SearchIcon from '@mui/icons-material/Search';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';

import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

// Services
import {
    deleteStaffContract as deleteContract,
    terminateStaffContract as terminateContract,
    getStaffs,
    updateStaff
} from 'services/staffs';
import VacationStaff from './VacationStaff';
import { getTypeDocuments } from 'services/contracts';

TableCell.defaultProps = { ...TableCell.defaultProps, style: { color: 'black' } };

const TableStaffs = ({ loading, setLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const page = useSelector((state) => state.staffs.page);
    const rowsPerPage = useSelector((state) => state.staffs.rowsPerPage);
    const filter = useSelector((state) => state.staffs.filter);

    const [defaultDocuments, setDefaultDocuments] = useState([]);

    const [staffs, setStaffs] = useState([]);
    const [paginate, setPaginate] = useState({});

    const [snackbarAlert, setSnackbarAlert] = useState({ open: false, message: '', severity: 'primary' });

    const getAllStaffs = useDebouncedCallback(() => {
        const contract = contractSelected ? contractSelected.id : '';
        const partner = partnerSelected ? partnerSelected.id : '';

        getStaffs(page, rowsPerPage, filter, contract, partner, true).then((response) => {
            const { data, total, current_page, per_page } = response.data;
            setStaffs(data);
            setPaginate({ total, current_page, per_page });
            setLoading(false);
        });
    }, 1000);

    const getDefaultTypesDocuments = () => {
        getTypeDocuments().then((response) => {
            const { data } = response;
            setDefaultDocuments(data?.filter((doc) => doc.padrao).map((doc) => doc.id));
        });
    };

    const handleFilter = (keyword) => {
        setLoading(true);
        dispatch({ type: 'SET_FILTER_STAFF', payload: keyword });
    };

    const createStaffContract = () => {
        if (!contractSelected) {
            return setSnackbarAlert({
                open: true,
                message: 'Selecione um contrato para adicionar novos efetivos',
                severity: 'error'
            });
        }
        navigate({ pathname: '/novo_efetivo' });
    };

    const updateStaffContract = (staff) => {
        if (!contractSelected) {
            return setSnackbarAlert({
                open: true,
                message: 'Selecione um contrato para cadastrar o efetivo',
                severity: 'error'
            });
        }
        updateStaff(staff.id, {
            cpf: staff.cpf,
            nome: staff.nome,
            contrato_id: contractSelected?.id,
            documentos: defaultDocuments
        }).then(() => {
            getAllStaffs();
        });
    };

    const terminateStaffContract = (staff) => {
        if (!contractSelected) {
            return setSnackbarAlert({
                open: true,
                message: 'Selecione um contrato para remover um efetivo',
                severity: 'error'
            });
        }
        terminateContract(staff.id, contractSelected.id).then(() => {
            getAllStaffs();
        });
    };

    const formatCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const formatDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    };

    const getVacations = (staff) => {
        return staff.ferias
            ?.filter((vacation) => moment(vacation.fim).isAfter(moment()))
            .sort((a, b) => moment(a.inicio).diff(moment(b.inicio)));
    };

    const renderVacation = (staff) => {
        const vacations = getVacations(staff);
        if (!vacations || vacations.length === 0) {
            return (
                <Chip
                    variant="filled"
                    size="small"
                    sx={{ backgroundColor: '#4D4D4D', color: 'white', fontWeight: '500' }}
                    label={'Sem férias'}
                />
            );
        }
        return (
            <Chip
                key={vacations[0].id}
                variant="filled"
                size="small"
                color="primary"
                label={`${formatDate(vacations[0].inicio)} - ${formatDate(vacations[0].fim)}`}
                sx={{ fontWeight: '500' }}
            />
        );
    };

    useEffect(() => {
        getAllStaffs();
    }, [partnerSelected, contractSelected, page, rowsPerPage, filter]);

    useEffect(() => {
        getDefaultTypesDocuments();
    }, []);

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ mb: 1, py: 1 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Pesquise pelo nome ou documento do efetivo"
                        variant="filled"
                        size="small"
                        InputProps={{
                            endAdornment: <SearchIcon />
                        }}
                        value={filter}
                        onChange={(e) => handleFilter(e.target.value)}
                    />
                </FormControl>
            </Stack>
            <TableContainer sx={{ borderRadius: '10px', position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Table>
                    <TableHead sx={{ backgroundColor: '#DCDCDC' }}>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>CPF</TableCell>
                            <TableCell>Contrato</TableCell>
                            <TableCell>Férias</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staffs.map((staff) => (
                            <TableRow key={staff.id}>
                                <TableCell>{staff.nome}</TableCell>
                                <TableCell>{formatCPF(staff.cpf)}</TableCell>
                                <TableCell>
                                    {staff.possui_contrato ? (
                                        <>
                                            {staff.data_recisao ? (
                                                <Chip
                                                    variant="filled"
                                                    size="small"
                                                    color="warning"
                                                    label={'Rescindido'}
                                                    sx={{ fontWeight: '500' }}
                                                />
                                            ) : (
                                                <Chip
                                                    variant="filled"
                                                    size="small"
                                                    sx={{ backgroundColor: '#19AB27', color: 'white', fontWeight: '500' }}
                                                    label={'Ativo'}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Chip
                                            variant="filled"
                                            size="small"
                                            sx={{ backgroundColor: '#4D4D4D', color: 'white', fontWeight: '500' }}
                                            label={'Sem contrato'}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>{renderVacation(staff)}</TableCell>
                                <TableCell>
                                    {staff.possui_contrato ? (
                                        <>
                                            <Tooltip title="Editar contrato" arrow>
                                                <IconButton onClick={() => navigate({ pathname: `/efetivo/${staff.id}/edit` })}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {!staff.data_recisao && (
                                                <>
                                                    <ConfirmationDialog
                                                        title="Atenção!"
                                                        description={`Você deseja rescindir o contrato ${contractSelected?.numero} com o efetivo ${staff.nome}?`}
                                                        response={() => terminateStaffContract(staff)}
                                                        severity="warning"
                                                        confirmationText="Sim, prosseguir"
                                                    >
                                                        {(showDialog) => (
                                                            <Tooltip title="Rescindir contrato" arrow>
                                                                <IconButton onClick={showDialog}>
                                                                    <PersonOffIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </ConfirmationDialog>
                                                    <VacationStaff
                                                        staff={staff}
                                                        contractSelected={contractSelected}
                                                        getAllStaffs={getAllStaffs}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {contractSelected && (
                                                <ConfirmationDialog
                                                    title="Atenção!"
                                                    description={`Você deseja adicionar o efetivo ${staff.nome} ao contrato ${contractSelected?.numero}?`}
                                                    response={() => updateStaffContract(staff)}
                                                    severity="primary"
                                                    confirmationText="Sim, prosseguir"
                                                >
                                                    {(showDialog) => (
                                                        <Tooltip title="Adicionar efetivo ao contrato" arrow>
                                                            <IconButton onClick={showDialog}>
                                                                <PostAddOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </ConfirmationDialog>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {staffs.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    Nenhum efetivo encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={5}
                                count={paginate.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                                labelRowsPerPage="Registros por página"
                                onPageChange={(e, newPage) => dispatch({ type: 'SET_PAGE_STAFF', payload: newPage })}
                                onRowsPerPageChange={(e) => dispatch({ type: 'SET_ROWS_PER_PAGE_STAFF', payload: e.target.value })}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial ariaLabel="SpeedDial basic example" sx={{ position: 'absolute', bottom: 16, right: 16 }} icon={<AddIcon />}>
                <SpeedDialAction icon={<PersonAddIcon />} tooltipTitle="Novo Efetivo" onClick={createStaffContract} />
            </SpeedDial>
            <Snackbar open={snackbarAlert.open} autoHideDuration={5000} onClose={() => setSnackbarAlert({ ...snackbarAlert, open: false })}>
                <Alert
                    severity={snackbarAlert.severity}
                    variant="filled"
                    onClose={() => setSnackbarAlert({ ...snackbarAlert, open: false })}
                >
                    {snackbarAlert.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default TableStaffs;
