import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AnimateButton from 'ui-component/extended/AnimateButton';
import TextField from '@mui/material/TextField';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonAdd from '@mui/icons-material/PersonAdd';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import GroupIcon from '@mui/icons-material/Group';
import { MENU_OPEN } from 'store/actions';
import ModalFilter from 'components/Modal/ModalFilter';
import { getPartners, deletePartner, changeStatus } from 'services/partners';
import MainCard from 'ui-component/cards/MainCard';
import { Handshake, HandshakeOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c0c0c0',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));
export default function GridPartners() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const page = useSelector((state) => state.partners.page);
    const namePartner = useSelector((state) => state.partners.namePartner);
    const rowsPerPage = useSelector((state) => state.partners.rowsPerPage);
    const [partners, setPartners] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [idDestroyPartner, setIdDestroyPartner] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyPartner(idDestroy);
        setOpenModal(true);
    };
    const handleCloseModal = () => setOpenModal(false);
    const handleCloseModalFilter = () => setOpenModalFilter(false);
    const withLink = (to, children) => <Link to={to}>{children}</Link>;

    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_PARTNER', payload: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_PARTNER', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_PARTNER', payload: 0 });
    };

    function getAllPartners(namePartnerAttr) {
        let namePartnersFilter = namePartnerAttr === '' ? namePartnerAttr : namePartner;
        getPartners(page + 1, rowsPerPage, namePartnersFilter).then((resp) => setPartners(resp.data));
    }
    const deletePartnerById = () => {
        handleCloseModal();
        deletePartner(idDestroyPartner)
            .then((resp) => {
                getAllPartners();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyPartner('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };
    const actions = [{ icon: withLink('/novo_parceiro', <HandshakeOutlined />), name: 'Novo Parceiro' }];

    const changeStatusActive = (partner_id) => {
        changeStatus(partner_id)
            .then(() => getAllPartners())
            .catch((error) => console.error(error));
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const formatCNPJ = (cnpj) => {
        const cleaned = ('' + cnpj).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
        if (match) {
            return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
        }
        return cnpj;
    };

    useEffect(() => {
        getAllPartners();
    }, [page, rowsPerPage]);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'usuarios');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'usuarios' });
        }
    }, []);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <HandshakeOutlined />
                    <Typography variant="h2" component="div">
                        Parceiros
                    </Typography>
                </Stack>
            }
        >
            <BasicModal
                open={openModal}
                title="Excluir Parceiro"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir parceiro?"
                onDelete={deletePartnerById}
            />
            <TableContainer component={Paper}>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '10px 0px 0px 0px' }}>
                                Nome
                            </StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>Telefone</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>E-mail</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>CNPJ</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>Status</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '0px 10px 0px 0px' }}>
                                Ações
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partners.data &&
                            partners.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell style={{ color: 'black' }}>{row.nome_fantasia}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black' }}>{formatPhoneNumber(row.telefone)}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black' }}>{row.email}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black' }}>{formatCNPJ(row.cnpj)}</StyledTableCell>
                                    <StyledTableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.ativo === 1 ? true : false}
                                            onChange={() => changeStatusActive(row.id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell width={'200px'}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Visualizar">
                                                <IconButton onClick={() => navigate({ pathname: `/parceiro/${row.id}/view` })}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => navigate({ pathname: `/parceiro/${row.id}/edit` })}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir">
                                                <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={6}
                                count={partners.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    top: '90%',
                    position: 'fixed',
                    right: 50,
                    zIndex: theme.zIndex.speedDial
                }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                    />
                ))}
            </SpeedDial>
        </MainCard>
    );
}
