import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MENU_OPEN } from 'store/actions';
import { getUsers, deleteUser, changeStatus } from 'services/users';
import MainCard from 'ui-component/cards/MainCard';
import { IconUser } from '@tabler/icons';
import { enqueueSnackbar } from 'notistack';

export default function GridUsers() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();

    const page = useSelector((state) => state.users.page);
    const nameUser = useSelector((state) => state.users.nameUser);
    const rowsPerPage = useSelector((state) => state.users.rowsPerPage);

    const [users, setUsers] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [idDestroyUser, setIdDestroyUser] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyUser(idDestroy);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const withLink = (to, children) => <Link to={to}>{children}</Link>;

    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_USER', payload: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_USER', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_USER', payload: 0 });
    };

    function getAllUsers(nameUserAttr) {
        let nameUsersFilter = nameUserAttr === '' ? nameUserAttr : nameUser;
        getUsers(page + 1, rowsPerPage, nameUsersFilter).then((resp) => setUsers(resp.data));
    }
    const deleteUserById = () => {
        handleCloseModal();
        deleteUser(idDestroyUser)
            .then((resp) => {
                getAllUsers();
                enqueueSnackbar('Usuário excluído com sucesso', { variant: 'success' });
                setIdDestroyUser('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };
    const actions = [{ icon: withLink('/novo_usuario', <IconUser />), name: 'Novo Usuário' }];

    const changeStatusActive = (user_id) => {
        changeStatus(user_id)
            .then(() => getAllUsers())
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getAllUsers();
    }, [page, rowsPerPage]);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'usuarios');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'usuarios' });
        }
    }, []);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUser />
                    <Typography variant="h2" component="div">
                        Usuários
                    </Typography>
                </Stack>
            }
        >
            <BasicModal
                open={openModal}
                title="Excluir Usuário"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir usuario?"
                onDelete={deleteUserById}
            />
            <TableContainer component={Paper}>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: '#ececec' }}>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Perfil</TableCell>
                            <TableCell width={'140px'}>Status</TableCell>
                            <TableCell width={'200px'}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.data &&
                            users.data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell style={{ color: 'black' }}>{row.name}</TableCell>
                                    <TableCell style={{ color: 'black' }}>{row.email}</TableCell>
                                    <TableCell style={{ color: 'black' }}>{row.perfil}</TableCell>
                                    <TableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.ativo === 1 ? true : false}
                                            onChange={() => changeStatusActive(row.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Visualizar">
                                                <IconButton onClick={() => navigate({ pathname: `/usuario/${row.id}/view` })}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => navigate({ pathname: `/usuario/${row.id}/edit` })}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir">
                                                <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={5}
                                count={users.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    top: '90%',
                    position: 'fixed',
                    right: 50,
                    zIndex: theme.zIndex.speedDial
                }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                    />
                ))}
            </SpeedDial>
        </MainCard>
    );
}
