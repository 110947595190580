// assets
import { HandshakeOutlined } from '@mui/icons-material';
import { IconHome, IconUser, IconLicense, IconUsers, IconDeviceDesktopAnalytics } from '@tabler/icons';

const partner = {
    id: 'utilities',
    type: 'group',
    titleName: true,
    children: [
        {
            id: 'index',
            title: 'Painel Inicial',
            type: 'item',
            url: '/index',
            icon: IconDeviceDesktopAnalytics,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'efetivos',
            title: 'Efetivos',
            type: 'item',
            url: '/efetivos',
            icon: IconUsers,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }
    ]
};

export default partner;
