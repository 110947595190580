import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UI
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import GroupIcon from '@mui/icons-material/Group';

// Services
import { getPartners } from 'services/partners';
import { getContracts } from 'services/contracts';

// Components
import TableStaffs from './TableStaffs';
import { IconUsers } from '@tabler/icons';

const PartnerStaffs = () => {
    const dispatch = useDispatch();

    const partnerSelected = useSelector((state) => state.staffs.partnerSelected);
    const contractSelected = useSelector((state) => state.staffs.contractSelected);

    const [partner, setPartner] = useState(null);
    const [partners, setPartners] = useState([]);

    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);

    const [loading, setLoading] = useState(true);

    const handlePartnerChange = (event) => {
        setLoading(true);
        setPartner(event.target.value);
    };

    const handleContractChange = (event) => {
        setLoading(true);
        setContract(event.target.value);
    };

    const getAllPartners = () => {
        getPartners().then((response) => {
            const { data } = response.data;
            setPartners(data);
        });
    };

    const getPartnerContracts = () => {
        getContracts(partner.id).then((response) => {
            const { data } = response.data;
            setContracts(data);
        });
    };

    useEffect(() => {
        if (contract) dispatch({ type: 'SET_CONTRACT_SELECTED', payload: contract });
    }, [contract]);

    useEffect(() => {
        if (partner) {
            dispatch({ type: 'SET_PARTNER_SELECTED', payload: partner });
            getPartnerContracts();
        }
    }, [partner]);

    useEffect(() => {
        if (contractSelected) setContract(contracts.find((item) => item.id == contractSelected.id));
        else if (contracts.length == 1) setContract(contracts[0]);
    }, [contracts]);

    useEffect(() => {
        if (partnerSelected) setPartner(partners.find((item) => item.id == partnerSelected.id));
        else if (partners.length == 1) setPartner(partners[0]);
    }, [partners]);

    useEffect(() => {
        getAllPartners();
    }, []);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUsers />
                    <Typography variant="h2" component="div">
                        Efetivos
                    </Typography>
                </Stack>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="partnerSelectLabel" shrink>
                            Selecione o parceiro
                        </InputLabel>
                        <Select
                            labelId="partnerSelectLabel"
                            label="Selecione o parceiro"
                            id="partnerSelect"
                            name="partnerSelect"
                            value={partner}
                            onChange={handlePartnerChange}
                            disabled={partners.length == 1}
                        >
                            {partners.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                    {item.nome_fantasia}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="contractSelectLabel" shrink>
                            Selecione o contrato
                        </InputLabel>
                        <Select
                            labelId="contractSelectLabel"
                            label="Selecione o contrato"
                            id="contractSelect"
                            name="contractSelect"
                            value={contract}
                            onChange={handleContractChange}
                            disabled={contracts.length == 1}
                        >
                            {contracts.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                    {item.numero}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableStaffs loading={loading} setLoading={setLoading} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PartnerStaffs;
