// assets
import { IconBoxPadding, IconWindmill, IconBuildingCommunity, IconBrandTidal } from '@tabler/icons';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';

// constant
const icons = { IconBoxPadding, IconWindmill, IconBuildingCommunity, IconBrandTidal };

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const contractsGroup = {
    id: 'docs',
    title: 'Parceiro',
    type: 'group',
    children: [
        {
            id: 'docs',
            title: 'Documentos',
            type: 'collapse',
            icon: FolderCopyOutlinedIcon,

            children: [
                {
                    id: 'docs-fiscal',
                    title: 'Habilitação',
                    type: 'item',
                    url: 'documentos-fiscais',
                    isChildren: true
                },
                {
                    id: 'docs-mensais',
                    title: 'Mensais',
                    type: 'item',
                    url: 'documentos-mensais',
                    isChildren: true
                }
            ]
        }
    ]
};

export default contractsGroup;
