import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';

const statusDocuments = {
    id: 'status-docs',
    title: 'Administração',
    type: 'group',
    children: [
        {
            id: 'docs',
            title: 'Documentos',
            type: 'collapse',
            icon: FolderCopyOutlinedIcon,
            children: [
                {
                    id: 'docs-a-validar',
                    title: 'A validar',
                    type: 'item',
                    url: 'documentos-a-validar',
                    isChildren: true
                },
                {
                    id: 'docs-pendentes',
                    title: 'Pendetes',
                    type: 'item',
                    url: 'documentos-pendentes',
                    isChildren: true
                }
            ]
        }
    ]
};

export default statusDocuments;
