import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import { IconCalendarEvent, IconMail, IconSeeding, IconUser } from '@tabler/icons';
import MainCard from 'ui-component/cards/MainCard';
import PasswordUpdate from './components/PasswordUpdate';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import moment from 'moment';

const roles = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Parceiro' },
    { id: 3, name: 'Gestor de contratos' }
];

const ProfileCard = styled(Card)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid #eaeaea',
    '& .MuiCardHeader-root': {
        borderBottom: '1px solid #eaeaea'
    }
}));

const Profile = () => {
    const user = useSelector((state) => state.auth.user);

    const theme = useTheme();

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUser />
                    <Typography variant="h2" component="div">
                        Perfil
                    </Typography>
                </Stack>
            }
        >
            <ProfileCard>
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{
                                ...theme.typography.mediumAvatar,
                                cursor: 'pointer',
                                bgcolor: '#ededed'
                            }}
                        >
                            {user?.name.length > 0 ? user?.name[0] : 'R'}
                        </Avatar>
                    }
                    title={user?.name}
                    subheader={roles.find((role) => role.id === user.perfil_id)?.name}
                />
                <CardContent>
                    <List
                        component="nav"
                        sx={{
                            pt: 0,
                            width: 300,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                                minWidth: '100%'
                            },
                            '& .MuiListItemButton-root': {
                                mt: 0.5
                            }
                        }}
                    >
                        <ListItem>
                            <ListItemIcon>
                                <IconMail />
                            </ListItemIcon>
                            <ListItemText primary="E-mail" secondary={user?.email} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <IconCalendarEvent />
                            </ListItemIcon>
                            <ListItemText primary="Data de cadastro" secondary={moment(user?.created_at).format('d/m/Y')} />
                        </ListItem>
                    </List>
                </CardContent>
            </ProfileCard>
            <Divider sx={{ my: 3 }} />
            <PasswordUpdate />
        </MainCard>
    );
};

export default Profile;
