import { Box, Divider, Modal, Stack, TextField, Typography } from '@mui/material';
import { ButtonsGroupStyled, ButtonStyled } from './styles';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { updateDocument } from 'services/documents';

const DocumentStatus = ({ onOpen, onClose, document, reloadDocuments }) => {
    const user = useSelector((state) => state.auth.user);

    const [status, setStatus] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);

    const handleEdit = () => {
        updateDocument(document.id, { status: status, validade: expiryDate })
            .then((resp) => {
                reloadDocuments();
                handleClose();
                enqueueSnackbar('Documento atualizado com sucesso!', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar('Erro ao atualizar documento!', { variant: 'error' });
            });
    };

    const handleClose = () => {
        setStatus(null);
        setExpiryDate(null);
        onClose();
    };

    useEffect(() => {
        if (document) {
            setStatus(document.status);
            setExpiryDate(document.validade);
        }
    }, [onOpen]);

    return (
        <Modal open={onOpen} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    borderRadius: '20px'
                }}
            >
                <Typography id="modal-title" variant="h3" component="h2">
                    Editar Documento
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ mt: 4 }}>
                    <TextField
                        label="Data de Validade"
                        type="date"
                        variant="outlined"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        fullWidth
                        disabled={user?.perfil_id === 2}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Alterar um status:
                    </Typography>
                    <ButtonsGroupStyled>
                        <ButtonStyled
                            style={{
                                ...(status === 'PENDENTE'
                                    ? { backgroundColor: '#f44336', color: 'white', border: 'none' }
                                    : { backgroundColor: 'transparent', color: 'inherit', border: '1px solid #f44336' }),
                                marginRight: '10px',
                                opacity: user?.perfil_id === 2 ? 0.5 : 1
                            }}
                            disabled={user?.perfil_id == 2}
                            onClick={() => setStatus('PENDENTE')}
                        >
                            PENDENTE
                        </ButtonStyled>
                        <ButtonStyled
                            style={{
                                ...(status === 'A VALIDAR'
                                    ? { backgroundColor: 'orange', color: 'white', border: 'none' }
                                    : { backgroundColor: 'transparent', color: 'inherit', border: '1px solid orange' }),
                                marginRight: '10px',
                                opacity: user?.perfil_id === 2 ? 0.5 : 1
                            }}
                            disabled={user?.perfil_id === 2}
                            onClick={() => setStatus('A VALIDAR')}
                        >
                            A VALIDAR
                        </ButtonStyled>
                        <ButtonStyled
                            style={{
                                ...(status === 'VALIDADO'
                                    ? { backgroundColor: '#4caf50', color: 'white', border: 'none' }
                                    : { backgroundColor: 'transparent', color: 'inherit', border: '1px solid #4caf50' }),
                                marginRight: '10px',
                                opacity: user?.perfil_id === 2 ? 0.5 : 1
                            }}
                            disabled={user?.perfil_id === 2}
                            onClick={() => setStatus('VALIDADO')}
                        >
                            VALIDADO
                        </ButtonStyled>
                    </ButtonsGroupStyled>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Stack display="flex" direction="row" justifyContent="flex-end" spacing={1}>
                        {user?.perfil_id !== 2 ? (
                            <>
                                <ButtonStyled onClick={handleClose} color="primary" variant="outlined" sx={{ marginRight: '5px' }}>
                                    Cancelar
                                </ButtonStyled>
                                <ButtonStyled onClick={handleEdit} color="primary" variant="contained">
                                    Salvar
                                </ButtonStyled>
                            </>
                        ) : (
                            <>
                                <ButtonStyled onClick={handleClose} color="primary" variant="outlined" sx={{ marginRight: '5px' }}>
                                    Fechar
                                </ButtonStyled>
                            </>
                        )}
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
};

export default DocumentStatus;
