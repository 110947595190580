import { useEffect, useState } from 'react';

// UI
import { Divider, Grid, Stack, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Toggle from 'react-toggle';

// Services
import { getGroupDocuments } from 'services/groupDocuments';
import { getTypeDocuments } from 'services/contracts';

const StaffDocuments = ({ documents, setDocuments }) => {
    const [documentGroups, setDocumentGroups] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);

    const [groupSelected, setGroupSelected] = useState(null);

    const getGroupsDocuments = () => {
        getGroupDocuments(1, '').then((resp) => {
            setDocumentGroups(resp.data);
        });
    };

    const getTypesDocuments = () => {
        getTypeDocuments().then((resp) => {
            setDocumentTypes(resp.data);
        });
    };

    useEffect(() => {
        if (!groupSelected && documentGroups.length) setGroupSelected(documentGroups[0]);
    }, [documents]);

    useEffect(() => {
        getGroupsDocuments();
        getTypesDocuments();
    }, []);

    return (
        <>
            <Typography variant="h3">Documentos</Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                        <InputLabel id="document-group-select" shrink>
                            Grupo de Documentos
                        </InputLabel>
                        <Select
                            labelId="document-group-select"
                            id="document-group-select"
                            value={groupSelected}
                            label="Grupo de Documentos"
                            onChange={(e) => setGroupSelected(e.target.value)}
                        >
                            {documentGroups.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                    {item.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {documentTypes
                        .filter((type) => type.grupo_documento_id == groupSelected?.id)
                        .map((type) => (
                            <Stack
                                key={type.id}
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    py: 4,
                                    px: 2,
                                    mb: 1,
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '0.5rem'
                                }}
                            >
                                <Toggle
                                    defaultChecked={documents?.find((doc) => doc === type.id)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setDocuments([...documents, type.id]);
                                        } else {
                                            setDocuments(documents?.filter((doc) => doc !== type.id));
                                        }
                                    }}
                                />
                                <Typography variant="subtitle1">
                                    {type.nome}
                                    {type.especificacoes && ` (${type.especificacoes})`}
                                </Typography>
                            </Stack>
                        ))}
                </Grid>
            </Grid>
        </>
    );
};

export default StaffDocuments;
