import { app } from './Api';
import { appblob } from './ApiBlob';

const updateDocument = async (id, data) => {
    return app.put(`documento/${id}`, data);
};

const createDocument = async (data) => {
    return app.post(`documento`, data);
};

const getFile = async (id, documento) => {
    return appblob.get(`documento/${documento}/arquivo/${id}`);
};

const getFiles = async (id) => {
    return app.get(`documento/${id}/arquivo`);
};

const uploadFile = async (data, config = null) => {
    return app.post(`documento/upload`, data, config);
};

const deleteFile = async (id, documento) => {
    return app.delete(`documento/${documento}/arquivo/${id}`);
};

const getPendingsDocs = async (search = '', page = '', rowsPerPage = '') => {
    return app.get(`documento/status/pendings?search=${search}&page=${page}&rowsPerPage=${rowsPerPage}`);
};

const getValidateDocs = async (search = '', page = '', rowsPerPage = '') => {
    return app.get(`documento/status/validate?search=${search}&page=${page}&rowsPerPage=${rowsPerPage}`);
};

export { updateDocument, createDocument, getFile, getFiles, uploadFile, deleteFile, getPendingsDocs, getValidateDocs };
