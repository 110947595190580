import { useState } from 'react';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

DialogTitle.defaultProps = { style: { fontSize: 18 } };
DialogContentText.defaultProps = { style: { color: 'black', fontSize: 14 } };

function ConfirmationDialog(props) {
    const [open, setOpen] = useState(false);

    const showDialog = () => {
        setOpen(true);
    };

    const hideDialog = () => {
        setOpen(false);
    };

    const confirmRequest = () => {
        props.response();
        hideDialog();
    };

    return (
        <>
            {props.children(showDialog)}
            {open && (
                <Dialog open={open} onClose={hideDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" sx={{ color: props.color }}>
                        {props.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 3 }}>
                        <Button onClick={confirmRequest} color={props.severity || 'primary'} variant="contained">
                            {props.confirmationText || 'Sim'}
                        </Button>
                        <Button onClick={hideDialog} variant="text" sx={{ color: 'gray' }}>
                            {props.closeText || 'Não'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default ConfirmationDialog;
