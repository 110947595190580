import {
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { IconUser } from '@tabler/icons';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getUserPerId, postUser, updateUser } from 'services/users';
import MainCard from 'ui-component/cards/MainCard';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';

const roles = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Parceiro' },
    { id: 3, name: 'Gestor de contratos' }
];

const User = () => {
    const params = useParams();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            perfil_id: 2,
            password: '',
            password_confirmation: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Campo obrigatório'),
            email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
            perfil_id: Yup.string().required('Campo obrigatório'),
            password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
        }),
        onSubmit: (values) => {
            if (getAction() === 'edit') return editUser(values);
            return addUser(values);
        }
    });

    const [loading, setLoading] = useState(false);

    const getAction = () => params?.action;

    const renderTitle = () => {
        switch (getAction()) {
            case 'view':
                return 'Visualizar Usuário';
            case 'edit':
                return 'Editar Usuário';
            default:
                return 'Novo Usuário';
        }
    };

    const fetchUser = async (id) => {
        setLoading(true);
        getUserPerId(id)
            .then((response) => {
                const { data } = response.data;
                formik.setValues({
                    name: data.name,
                    email: data.email,
                    perfil_id: data.perfil_id
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addUser = async (values) => {
        setLoading(true);
        postUser(values)
            .then((response) => {
                enqueueSnackbar('Usuário cadastrado com sucesso', { variant: 'success' });
                navigate(-1);
            })
            .catch((error) => {
                formik.setErrors(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editUser = async (values) => {
        setLoading(true);
        updateUser(params.id, values)
            .then((response) => {
                enqueueSnackbar('Usuário atualizado com sucesso', { variant: 'success' });
                navigate(-1);
            })
            .catch((error) => {
                formik.setErrors(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (params.id) fetchUser(params.id);
    }, [params.id]);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconUser />
                    <Typography variant="h2" component="div">
                        {renderTitle()}
                    </Typography>
                </Stack>
            }
        >
            <form onSubmit={formik.handleSubmit} style={{ position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="div">
                            Dados de acesso
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Nome"
                                variant="outlined"
                                value={formik.values.name}
                                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                disabled={getAction() === 'view'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="E-mail"
                                variant="outlined"
                                value={formik.values.email}
                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                disabled={getAction() === 'view'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="select-profile">Perfil</InputLabel>
                            <Select
                                label="Perfil"
                                labelId="select-profile"
                                variant="outlined"
                                value={formik.values.perfil_id}
                                onChange={(e) => formik.setFieldValue('perfil_id', e.target.value)}
                                error={formik.touched.perfil_id && Boolean(formik.errors.perfil_id)}
                                disabled={getAction() === 'view'}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {getAction() === 'edit' && (
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="div">
                                Alterar senha
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Senha"
                                    variant="outlined"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={(e) => formik.setFieldValue('password', e.target.value)}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    disabled={getAction() === 'view'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Confirmação"
                                    variant="outlined"
                                    type="password"
                                    value={formik.values.password_confirmation}
                                    onChange={(e) => formik.setFieldValue('password_confirmation', e.target.value)}
                                    error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                    helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                    disabled={getAction() === 'view'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button
                                variant="contained"
                                sx={{ bgcolor: 'gray', ':hover': { bgcolor: 'gray' } }}
                                size="large"
                                onClick={() => navigate(-1)}
                            >
                                Voltar
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={formik.isSubmitting || getAction() === 'view'}
                            >
                                Salvar
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </MainCard>
    );
};

export default User;
