import { Alert, Box, Button, Divider, Grid, IconButton, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import EventIcon from '@mui/icons-material/Event';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { disableVacation, postVacation } from 'services/vacation';

import moment from 'moment';
import ConfirmationDialog from 'ui-component/dialogs/ConfirmationDialog';

const VacationStaff = ({ staff, contractSelected, getAllStaffs }) => {
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const [vacations, setVacations] = useState([]);

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const showDialog = () => {
        setOpen(true);
    };

    const hideDialog = () => {
        setErrors({});
        setStart('');
        setEnd('');
        setOpen(false);
    };

    const handlePostVacation = () => {
        postVacation({
            efetivo_id: staff.id,
            contrato_id: contractSelected.id,
            inicio: start,
            fim: end
        })
            .then((response) => {
                getAllStaffs();
                hideDialog();
            })
            .catch((e) => {
                const { errors, error } = e.response.data;
                setErrors(errors || { message: error });
            });
    };

    const handleDisableVacation = (id) => {
        disableVacation(id)
            .then(() => {
                getAllStaffs();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getVacations = () => {
        return staff.ferias
            ?.filter((vacation) => moment(vacation.fim).isAfter(moment()))
            .sort((a, b) => moment(a.inicio).diff(moment(b.inicio)));
    };

    useEffect(() => {
        setVacations(getVacations());
    }, [staff]);

    return (
        <>
            {!vacations.length && (
                <Tooltip title="Adicionar período de férias" arrow>
                    <IconButton onClick={showDialog}>
                        <EventIcon />
                    </IconButton>
                </Tooltip>
            )}
            {vacations[0] && (
                <ConfirmationDialog
                    title="Atenção!"
                    description={`Você deseja remover o período de férias de ${staff.nome}?`}
                    response={() => handleDisableVacation(vacations[0].id)}
                    severity="error"
                    confirmationText="Sim, prosseguir"
                >
                    {(showDialog) => (
                        <Tooltip title="Cancelar férias" arrow>
                            <IconButton onClick={showDialog}>
                                <EventBusyIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </ConfirmationDialog>
            )}
            <Modal open={open} onClose={hideDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Typography sx={{ mb: 4 }} variant="h3" component="h3">
                        Adicionar período de férias
                    </Typography>
                    {errors.message && (
                        <Alert sx={{ mb: 3 }} severity="error">
                            {errors.message}
                        </Alert>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="start"
                                value={start}
                                onChange={(e) => setStart(e.target.value)}
                                label="Início"
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <Typography sx={{ fontSize: 12, mt: 1 }} color="error">
                                {errors.inicio && errors.inicio[0]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="end"
                                value={end}
                                onChange={(e) => setEnd(e.target.value)}
                                label="Fim"
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <Typography sx={{ fontSize: 12, mt: 1 }} color="error">
                                {errors.fim && errors.fim[0]}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Stack sx={{ mt: 3 }} direction="row" justifyContent="flex-end" spacing={2}>
                        <Button onClick={handlePostVacation} color="primary" variant="contained">
                            Adicionar
                        </Button>
                        <Button onClick={hideDialog} variant="text" sx={{ color: 'gray' }}>
                            Cancelar
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default VacationStaff;
