// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { menuItems, partnerItems } from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
    const user = useSelector((state) => state.auth.user);
    let nav = '';

    nav = user.perfil_id == 2 ? partnerItems : menuItems;

    const navItems = nav.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
