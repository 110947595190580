import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// chart data
import chartData from './chart-data/total-growth-bar-chart';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const TotalGrowthBarChart = ({ isLoading, data }) => {
    const [chart, setChart] = React.useState(chartData);

    const theme = useTheme();

    const documentsTotal = () => {
        if (!data) return 0;
        return data?.documentos?.pendings + data?.documentos?.toValidate + data?.documentos?.validated;
    };

    useEffect(() => {
        const pendings = parseInt(data?.documentos?.pendings);
        const toValidate = parseInt(data?.documentos?.toValidate);
        const validated = parseInt(data?.documentos?.validated);

        setChart({
            ...chart,
            options: {
                ...chart.options,
                xaxis: {
                    type: 'category',
                    categories: ['Pendentes', 'A validar', 'Validados']
                }
            },
            series: [
                {
                    name: 'Documentos',
                    data: [pendings, toValidate, validated]
                }
            ]
        });
    }, [data]);

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2">Total de documentos</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">{documentsTotal()}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                '& .apexcharts-menu.apexcharts-menu-open': {
                                    bgcolor: 'background.paper'
                                }
                            }}
                        >
                            <Chart {...chart} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

TotalGrowthBarChart.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalGrowthBarChart;
