import {
    Button,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { IconFileExport, IconFileReport } from '@tabler/icons';
import { getPendingsDocs } from 'services/documents';
import { useEffect, useState } from 'react';
import { AttachFile, Search, Visibility } from '@mui/icons-material';
import DocumentFiles from 'components/DocumentFiles';
import { useDebouncedCallback } from 'use-debounce';
import UploadDocumentFiles from 'components/UploadDocumentFiles';
import { CSVLink } from 'react-csv';

const DocsPending = () => {
    const [documentos, setDocumentos] = useState([]);

    const [documentoSelected, setDocumentoSelected] = useState(null);

    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const fetchDocumentos = useDebouncedCallback(() => {
        getPendingsDocs(search, page + 1, rowsPerPage).then((response) => {
            setDocumentos(response.data);
            setLoading(false);
        });
    }, 1000);

    const renderStatus = (status) => {
        switch (status) {
            case 'A VALIDAR':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="warning" label="A validar" />;
            case 'VALIDADO':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="success" label="Validado" />;
            case 'PENDENTE':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="error" label="Pendente" />;
            default:
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="error" label="Pendente" />;
        }
    };

    const renderCSVData = () => {
        if (documentos?.data) {
            const data = documentos?.data?.map((doc) => {
                return {
                    empresa: doc.empresa,
                    nome_fantasia: doc.nome_fantasia,
                    contrato: doc.contrato,
                    efetivo: doc.efetivo,
                    grupo_doc: doc.grupo_doc,
                    tipo_doc: doc.tipo_doc,
                    mensal: doc.mensal,
                    status: doc.status
                };
            });

            return (
                <CSVLink data={data} filename="pendentes.csv" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <Button variant="outlined" color="success" startIcon={<IconFileExport />} size="large">
                        CSV
                    </Button>
                </CSVLink>
            );
        }
    };

    useEffect(() => {
        fetchDocumentos();
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchDocumentos();
    }, [search, page, rowsPerPage]);

    return (
        <MainCard
            title={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconFileReport />
                    <Typography variant="h2" component="div">
                        Documentos pendentes
                    </Typography>
                </Stack>
            }
        >
            <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Pesquisar"
                        variant="filled"
                        size="small"
                        InputProps={{
                            endAdornment: <Search />
                        }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                </FormControl>
                {renderCSVData()}
            </Stack>
            <TableContainer sx={{ borderRadius: '10px', position: 'relative' }}>
                {loading && (
                    <Grid
                        container
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
                <Table>
                    <TableHead sx={{ backgroundColor: '#DCDCDC' }}>
                        <TableRow>
                            <TableCell>Empresa</TableCell>
                            <TableCell>Nome Fantasia</TableCell>
                            <TableCell>Contrato</TableCell>
                            <TableCell>Efetivo</TableCell>
                            <TableCell>Grupo de Documento</TableCell>
                            <TableCell>Tipo de Documento</TableCell>
                            <TableCell>Mensal</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Ação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentos?.data?.map((documento) => (
                            <TableRow key={documento.id}>
                                <TableCell>{documento.empresa}</TableCell>
                                <TableCell>{documento.nome_fantasia}</TableCell>
                                <TableCell>{documento.contrato}</TableCell>
                                <TableCell>{documento.efetivo}</TableCell>
                                <TableCell>{documento.grupo_doc}</TableCell>
                                <TableCell>{documento.tipo_doc}</TableCell>
                                <TableCell>
                                    {documento.mensal ? (
                                        <Chip style={{ width: '100px', textAlign: 'center' }} color="success" label="Sim" />
                                    ) : (
                                        <Chip style={{ width: '100px', textAlign: 'center' }} label="Não" />
                                    )}
                                </TableCell>
                                <TableCell>{renderStatus(documento.status)}</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        <IconButton
                                            onClick={() => {
                                                setDocumentoSelected(documento);
                                                setOpenFilesModal(true);
                                            }}
                                        >
                                            <Visibility />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setDocumentoSelected(documento);
                                                setOpenUploadModal(true);
                                            }}
                                        >
                                            <AttachFile />
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                        {documentos?.data?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    Nenhum documento encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={9}
                                count={documentos.total || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                onPageChange={(e, newPage) => setPage(newPage)}
                                onRowsPerPageChange={(e) => {
                                    setPage(0);
                                    setRowsPerPage(e.target.value);
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <DocumentFiles
                openModal={openFilesModal}
                handleCloseModal={() => setOpenFilesModal(false)}
                documentSelected={documentoSelected ? { id: documentoSelected.documento_id } : null}
                typeDocumentSelected={
                    documentoSelected?.tipo_doc_id ? { id: documentoSelected?.tipo_doc_id, nome: documentoSelected?.tipo_doc } : null
                }
                reloadDocuments={() => {
                    fetchDocumentos();
                }}
            />
            <UploadDocumentFiles
                openModal={openUploadModal}
                handleCloseModal={() => {
                    setOpenUploadModal(false);
                }}
                accept={['application/pdf']}
                contractSelected={documentoSelected?.contrato_id}
                typeDocumentSelected={{
                    id: documentoSelected?.tipo_doc_id,
                    nome: documentoSelected?.tipo_doc
                }}
                staffSelected={
                    documentoSelected?.efetivo_id ? { id: documentoSelected?.efetivo_id, nome: documentoSelected?.efetivo } : null
                }
                reloadDocuments={() => {
                    fetchDocumentos();
                }}
            />
        </MainCard>
    );
};

export default DocsPending;
