import { app } from './Api';

const postVacation = async (data) => {
    return app.post('ferias', data);
};

const disableVacation = async (id) => {
    return app.delete(`ferias/${id}`);
};

export { postVacation, disableVacation };
