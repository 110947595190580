import utilities from './utilities';
import partner from './partner';
import contractsGroup from './contractsGroup';
import staffsDocumentsGroup from './staffsDocumentsGroup';
import statusDocuments from './statusDocuments';

const partnerItems = {
    items: [partner, contractsGroup, staffsDocumentsGroup]
};

const menuItems = {
    items: [utilities, contractsGroup, staffsDocumentsGroup, statusDocuments]
};

export { partnerItems, menuItems };
