import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    MenuItem,
    Snackbar,
    Modal,
    Box,
    TextField,
    Typography,
    Alert,
    Button,
    Collapse
} from '@mui/material';
import { Visibility, AttachFile, Edit } from '@mui/icons-material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import { updateDocument, createDocument } from 'services/documents';
import { getTypeDocumentsContracts } from 'services/contracts';
import { ButtonStyled, ButtonsGroupStyled } from './styles';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { getStaffs } from 'services/staffs';
import BusinessIcon from '@mui/icons-material/Business';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { getGroupDocuments } from 'services/groupDocuments';
import UploadDocumentFiles from 'components/UploadDocumentFiles';
import DocumentFiles from 'components/DocumentFiles';

const TableDocuments = ({ contractSelected }) => {
    const [open, setOpen] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openFilesModal, setOpenFilesModal] = useState(false);
    const [expiryDate, setExpiryDate] = useState('');
    const [selectedButton, setSelectedButton] = useState(null);
    const [documentSelected, setDocumentSelected] = useState(null);
    const [allDocuments, setAllDocuments] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [staffSelected, setStaffSelected] = useState(null);
    const [groupDocuments, setGroupDocuments] = useState(null);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [expandedRow, setExpandedRow] = useState({ rowExpanded: null, doc_id: null });

    const user = useSelector((state) => state.auth.user) || '';

    const handleExpandRow = (rowId, doc_id) => {
        setAllDocuments([]);
        if (expandedRow?.rowExpanded === rowId && expandedRow?.doc_id === doc_id) {
            setExpandedRow({ rowExpanded: null, doc_id: null });
        } else {
            setExpandedRow({ rowExpanded: rowId, doc_id: doc_id });
        }
        getTypesDocuments(doc_id, rowId);
    };

    function getAllGroupDocuments() {
        let efetivo = true;
        getGroupDocuments(efetivo).then((resp) => {
            setGroupDocuments(resp.data);
        });
    }

    function getTypesDocuments(doc_id = null, staff_id = null) {
        getTypeDocumentsContracts(doc_id, contractSelected, staff_id).then((resp) => {
            setAllDocuments(resp.data);
        });
    }

    function getAllStaffs() {
        getStaffs('', '', '', contractSelected, '', true).then((resp) => {
            let staffs = resp.data?.data.filter((staff) => staff.possui_contrato);
            setStaffs(staffs);
        });
    }

    const handleClickOpen = (documento) => {
        setDocumentSelected(documento);
        setSelectedButton(documento.status);
        setExpiryDate(documento.validade);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDocumentSelected(null);
    };

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    const handleEdit = () => {
        updateDocument(documentSelected.id, { status: selectedButton, validade: expiryDate })
            .then((resp) => {
                getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                setSuccess(resp.data.success);
                setOpen(false);
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                setError(e.response.data.error || e.response.data.errors.validade[0]);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const renderStatus = (status) => {
        switch (status) {
            case 'A VALIDAR':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="warning" label="A validar" />;
            case 'VALIDADO':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="success" label="Validado" />;
            case 'PENDENTE':
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="error" label="Pendente" />;
            default:
                return <Chip style={{ width: '100px', textAlign: 'center' }} color="error" label="Pendente" />;
        }
    };

    useEffect(() => {
        if (contractSelected !== null) {
            getAllStaffs();
        } else {
            setStaffs([]);
        }
        setExpandedRow({ rowExpanded: null, doc_id: null });
    }, [contractSelected]);

    useEffect(() => {
        getAllGroupDocuments();
    }, []);

    return (
        <>
            {staffs?.length > 0 && groupDocuments.length > 0 ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'55%'}>Documentos</TableCell>
                                    <TableCell align="left">Setor</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {staffs?.map((staff) => (
                                    <React.Fragment key={staff.id}>
                                        <TableRow>
                                            <TableCell>{staff.nome}</TableCell>
                                            <TableCell align="left">
                                                {groupDocuments?.map((doc) => {
                                                    switch (doc.id) {
                                                        case 3:
                                                            return (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        handleExpandRow(staff.id, doc?.id);
                                                                        setStaffSelected(staff);
                                                                    }}
                                                                    style={{
                                                                        width: '100px',
                                                                        fontSize: '12px',
                                                                        backgroundColor: '#CEB3FF'
                                                                    }}
                                                                    sx={{ m: 1 }}
                                                                    startIcon={<BusinessIcon />}
                                                                >
                                                                    {doc?.nome}
                                                                </Button>
                                                            );
                                                        case 5:
                                                            return (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        handleExpandRow(staff.id, doc?.id);
                                                                        setStaffSelected(staff);
                                                                    }}
                                                                    style={{
                                                                        width: '100px',
                                                                        fontSize: '12px',
                                                                        backgroundColor: '#898DFF'
                                                                    }}
                                                                    sx={{ m: 1 }}
                                                                    startIcon={<LocalHospitalIcon />}
                                                                >
                                                                    {doc?.nome}
                                                                </Button>
                                                            );
                                                        case 7:
                                                            return (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        handleExpandRow(staff.id, doc?.id);
                                                                        setStaffSelected(staff);
                                                                    }}
                                                                    style={{
                                                                        width: '100px',
                                                                        fontSize: '12px',
                                                                        backgroundColor: '#98B8FF'
                                                                    }}
                                                                    sx={{ m: 1 }}
                                                                    startIcon={<GppGoodIcon />}
                                                                >
                                                                    {doc?.nome}
                                                                </Button>
                                                            );
                                                        default:
                                                            break;
                                                    }
                                                })}
                                                {staff?.data_recisao && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            handleExpandRow(staff.id, 9);
                                                            setStaffSelected(staff);
                                                        }}
                                                        style={{
                                                            width: '100px',
                                                            fontSize: '12px',
                                                            backgroundColor: '#E78F81'
                                                        }}
                                                        sx={{ m: 1 }}
                                                        startIcon={<PersonOffIcon />}
                                                    >
                                                        Rescisão
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#f5f5f5' }} colSpan={4}>
                                                <Collapse
                                                    in={expandedRow.rowExpanded === staff.id && expandedRow.doc_id !== null}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Documentos</TableCell>
                                                                    <TableCell>Status</TableCell>
                                                                    <TableCell>Visualizar</TableCell>
                                                                    <TableCell>Anexar</TableCell>
                                                                    <TableCell>Editar</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {allDocuments?.map((document) => (
                                                                    <TableRow key={document.id}>
                                                                        <TableCell>{document.nome}</TableCell>
                                                                        <TableCell>{renderStatus(document?.documento?.status)}</TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                disabled={document.documento === null}
                                                                                onClick={() => {
                                                                                    setTypeDocumentSelected(document);
                                                                                    setDocumentSelected(document.documento);
                                                                                    setOpenFilesModal(true);
                                                                                }}
                                                                            >
                                                                                <Visibility />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <div style={{ display: 'flex' }}>
                                                                                <IconButton
                                                                                    disabled={
                                                                                        document?.documento?.status == 'VALIDADO' &&
                                                                                        new Date(document?.documento?.validade) > new Date()
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setTypeDocumentSelected(document);
                                                                                        setDocumentSelected(document.documento);
                                                                                        setOpenUploadModal(true);
                                                                                    }}
                                                                                >
                                                                                    <AttachFile />
                                                                                </IconButton>
                                                                                {document.documento != null ? (
                                                                                    <CheckCircleIcon
                                                                                        sx={{
                                                                                            color: 'green',
                                                                                            width: '15px',
                                                                                            marginRight: '5px',
                                                                                            position: 'absolute'
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                disabled={document.documento === null}
                                                                                onClick={() => handleClickOpen(document.documento)}
                                                                            >
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                {allDocuments.length === 0 && (
                                                                    <TableRow>
                                                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                                                            Nenhum documento encontrado
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 2,
                                borderRadius: '20px'
                            }}
                        >
                            <Typography id="modal-title" variant="h3" component="h2">
                                Editar Documento
                            </Typography>
                            <hr></hr>
                            <Box sx={{ mt: 4 }}>
                                <TextField
                                    label="Data de Validade"
                                    type="date"
                                    variant="outlined"
                                    value={expiryDate}
                                    onChange={(e) => setExpiryDate(e.target.value)}
                                    fullWidth
                                    disabled={user?.perfil_id === 2}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Alterar um status:
                                </Typography>
                                <ButtonsGroupStyled>
                                    <ButtonStyled
                                        style={{
                                            backgroundColor: selectedButton === 'PENDENTE' ? '#f44336' : 'transparent',
                                            color: selectedButton === 'PENDENTE' ? 'white' : 'inherit',
                                            border: selectedButton !== 'PENDENTE' ? '1px solid #f44336' : 'none',
                                            marginRight: '10px',
                                            opacity: user?.perfil_id === 1 || user?.perfil_id === 2 ? 0.5 : 1
                                        }}
                                        disabled={user?.perfil_id === 1 || user?.perfil_id === 2}
                                        onClick={() => handleButtonClick('PENDENTE')}
                                    >
                                        PENDENTE
                                    </ButtonStyled>
                                    <ButtonStyled
                                        style={{
                                            backgroundColor: selectedButton === 'A VALIDAR' ? 'orange' : 'transparent',
                                            color: selectedButton === 'A VALIDAR' ? 'white' : 'inherit',
                                            border: selectedButton !== 'A VALIDAR' ? '1px solid orange' : 'none',
                                            marginRight: '10px',
                                            opacity: user?.perfil_id === 1 || user?.perfil_id === 2 ? 0.5 : 1
                                        }}
                                        disabled={user?.perfil_id === 1 || user?.perfil_id === 2}
                                        onClick={() => handleButtonClick('A VALIDAR')}
                                    >
                                        A VALIDAR
                                    </ButtonStyled>
                                    <ButtonStyled
                                        style={{
                                            backgroundColor: selectedButton === 'VALIDADO' ? '#4caf50' : 'transparent',
                                            color: selectedButton === 'VALIDADO' ? 'white' : 'inherit',
                                            border: selectedButton !== 'VALIDADO' ? '1px solid #4caf50' : 'none',
                                            marginRight: '10px',
                                            opacity: user?.perfil_id === 1 || user?.perfil_id === 2 ? 0.5 : 1
                                        }}
                                        disabled={user?.perfil_id === 1 || user?.perfil_id === 2}
                                        onClick={() => handleButtonClick('VALIDADO')}
                                    >
                                        VALIDADO
                                    </ButtonStyled>
                                </ButtonsGroupStyled>
                            </Box>

                            <Box sx={{ mt: 4 }}>
                                <hr></hr>
                                <div style={{ display: 'flex', justifyContent: 'right', width: '100%', gap: '5px' }}>
                                    {user?.perfil_id !== 2 ? (
                                        <>
                                            <div>
                                                <ButtonStyled
                                                    onClick={handleClose}
                                                    color="primary"
                                                    variant="outlined"
                                                    sx={{ marginRight: '5px' }}
                                                >
                                                    Cancelar
                                                </ButtonStyled>
                                            </div>
                                            <div>
                                                <ButtonStyled onClick={handleEdit} color="primary" variant="contained">
                                                    Salvar
                                                </ButtonStyled>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <ButtonStyled
                                                onClick={handleClose}
                                                color="primary"
                                                variant="outlined"
                                                sx={{ marginRight: '5px' }}
                                            >
                                                Fechar
                                            </ButtonStyled>
                                        </div>
                                    )}
                                </div>
                            </Box>
                        </Box>
                    </Modal>
                    <DocumentFiles
                        openModal={openFilesModal}
                        handleCloseModal={() => {
                            setOpenFilesModal(false);
                            setDocumentSelected(null);
                        }}
                        setSuccess={setSuccess}
                        documentSelected={documentSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        reloadDocuments={() => {
                            getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                        }}
                    />
                    <UploadDocumentFiles
                        openModal={openUploadModal}
                        handleCloseModal={() => {
                            setOpenUploadModal(false);
                            setDocumentSelected(null);
                        }}
                        accept={['application/pdf']}
                        setSuccess={setSuccess}
                        contractSelected={contractSelected}
                        typeDocumentSelected={typeDocumentSelected}
                        staffSelected={staffSelected}
                        reloadDocuments={() => {
                            getTypesDocuments(expandedRow.doc_id, expandedRow.rowExpanded);
                        }}
                    />
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                </>
            ) : contractSelected === null || contractSelected === '' ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 style={{ textAlign: 'center' }}>Selecione um contrato</h3>
                    <ErrorIcon color="warning" />
                </div>
            ) : (
                <h3 style={{ textAlign: 'center', marginTop: '100px' }}>Nenhum dado encontrado</h3>
            )}
        </>
    );
};

export default TableDocuments;
