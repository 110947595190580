import PropTypes from 'prop-types';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
import GridPartners from 'views/pages/Partners/GridPartners';
import Partner from 'views/pages/Partners/Partner';
import GridContracts from 'views/pages/Contract/GridContracts';
import Contract from 'views/pages/Contract/Contract';
import GridUsers from 'views/pages/Users/GridUsers';
import User from 'views/pages/Users/User';
import DocsFiscais from 'views/pages/DocsFiscais';
import DocsMensais from 'views/pages/DocsMensais';
import DocsStaffs from 'views/pages/DocsStaffs';
import DocsStaffsMonthly from 'views/pages/DocsStaffsMonthly';
import PartnerStaffs from 'views/pages/PartnerStaffs';
import FormStaff from 'views/pages/PartnerStaffs/FormStaff';
import DocsToValidate from 'views/pages/DocsToValidate';
import DocsPending from 'views/pages/DocsPending';
import Dashboard from 'views/dashboard';
import Profile from 'views/profile';
const Home = Loadable(lazy(() => import('views/pages/Home/Home')));

// ==============================|| MAIN ROUTING ||============================== //

const AuthRouter = ({ children }) => {
    let token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
};
AuthRouter.propTypes = {
    children: PropTypes.object
};
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/index',
            element: (
                <AuthRouter>
                    <Dashboard />
                </AuthRouter>
            )
        },
        {
            path: '/parceiros',
            element: (
                <AuthRouter>
                    <GridPartners />
                </AuthRouter>
            )
        },
        {
            path: '/parceiro/:id/:action',
            element: (
                <AuthRouter>
                    <Partner />
                </AuthRouter>
            )
        },
        {
            path: '/novo_parceiro',
            element: (
                <AuthRouter>
                    <Partner />
                </AuthRouter>
            )
        },
        {
            path: '/contratos',
            element: (
                <AuthRouter>
                    <GridContracts />
                </AuthRouter>
            )
        },
        {
            path: '/novo_contrato',
            element: (
                <AuthRouter>
                    <Contract />
                </AuthRouter>
            )
        },
        {
            path: '/contrato/:id/:action',
            element: (
                <AuthRouter>
                    <Contract />
                </AuthRouter>
            )
        },
        {
            path: '/usuarios',
            element: (
                <AuthRouter>
                    <GridUsers />
                </AuthRouter>
            )
        },
        {
            path: '/novo_usuario',
            element: (
                <AuthRouter>
                    <User />
                </AuthRouter>
            )
        },
        {
            path: '/usuario/:id/:action',
            element: (
                <AuthRouter>
                    <User />
                </AuthRouter>
            )
        },
        {
            path: '/documentos-fiscais',
            element: (
                <AuthRouter>
                    <DocsFiscais />
                </AuthRouter>
            )
        },
        {
            path: '/documentos-mensais',
            element: (
                <AuthRouter>
                    <DocsMensais />
                </AuthRouter>
            )
        },
        {
            path: '/efetivos',
            element: (
                <AuthRouter>
                    <PartnerStaffs />
                </AuthRouter>
            )
        },
        {
            path: '/novo_efetivo',
            element: (
                <AuthRouter>
                    <FormStaff />
                </AuthRouter>
            )
        },
        {
            path: '/efetivo/:id/:action',
            element: (
                <AuthRouter>
                    <FormStaff />
                </AuthRouter>
            )
        },
        {
            path: '/documentos-efetivos',
            element: (
                <AuthRouter>
                    <DocsStaffs />
                </AuthRouter>
            )
        },
        {
            path: '/documentos-mensais-efetivos',
            element: (
                <AuthRouter>
                    <DocsStaffsMonthly />
                </AuthRouter>
            )
        },
        {
            path: '/documentos-a-validar',
            element: (
                <AuthRouter>
                    <DocsToValidate />
                </AuthRouter>
            )
        },
        {
            path: '/documentos-pendentes',
            element: (
                <AuthRouter>
                    <DocsPending />
                </AuthRouter>
            )
        },
        {
            path: '/perfil',
            element: (
                <AuthRouter>
                    <Profile />
                </AuthRouter>
            )
        }
    ]
};

export default MainRoutes;
